import { capitalCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Card, Stack, Link, Alert, Tooltip, Container, Typography } from '@mui/material';
// routes
import { PATH_AUTH_LINGUIST } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useResponsive from '../../../hooks/useResponsive';
// components
import Page from '../../../components/Page';
import Logo from '../../../components/Logo';
import Image from '../../../components/Image';
// sections
import { LoginForm } from '../../../sections/auth/linguistAuth/login';
import Iconify from 'src/components/Iconify';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '80%',
  minHeight: '80vh',
  display: 'flex',
  color: '#fff',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundPosition: 'center',
  backgroundPositionX: 'right',
  backgroundPositionY: 'top',
  backgroundSize: '100%',
  backgroundRepeat: 'no-repeat',
  backgroundImage: "url('/vendors/registration.webp')",
  maxWidth: '1300px',
  // margin: theme.spacing(2, 0, 2, 2),
  borderRadius: '0px',
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 550,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(4, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { method } = useAuth();

  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  return (
    <Page title="Login">
      <RootStyle>
        <Container>
          <ContentStyle>
            <img src="/logo/lingoyou-logo-full.svg" style={{ width: '130px', marginBottom:"25px", marginInline: 'auto' }} />
           
            {/* <Alert severity="info" sx={{ mb: 3 }}>
              Use email : <strong>admin@admin.com</strong> / password : <strong>12345</strong>
            </Alert> */}

            <LoginForm />
            <Box sx={{ mt: 5, pt:2, borderTop:"1px solid lightgrey", display: 'flex', alignItems: 'center' }}>
              <Box sx={{ flexGrow: 1, mt: 2 }}>
                <Typography variant="body1" align="center" sx={{ mt: 3 }}>
                  Don’t have an account?{' '}
                  <Link sx={{fontWeight:"600"}} component={RouterLink} to={PATH_AUTH_LINGUIST.register}>
                    Get started
                  </Link>
                </Typography>
              </Box>
            </Box>
          </ContentStyle>
        </Container>
        {mdUp && (
          <SectionStyle>
            <img src="/vendors/lingo-table1.png" style={{ objectFit: 'contain', marginInline: 'auto' }} />
            <Stack>
              <Typography variant="h3" sx={{ mb: 3, textAlign: 'center' }}>
                Welcome to the LY Linguist Planet
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  ml: 1,
                  fontStyle: 'italic',
                  maxWidth: '80%',
                  marginInline: 'auto',
                  textAlign: 'center',
                  fontSize: '22px',
                  lineHeight: '30px',
                }}
              >
                All Your Tasks, Insights, and Resources in One Place.
              </Typography>
            </Stack>
          </SectionStyle>
        )}
      </RootStyle>
    </Page>
  );
}
