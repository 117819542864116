import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Link, Typography } from '@mui/material';
// hooks
import useAuth from '../../../hooks/useAuth';
// routes
import { PATH_CLIENT, PATH_DASHBOARD, PATH_LINGUIST } from '../../../routes/paths';
// components
import MyAvatar from '../../../components/MyAvatar';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

NavbarAccount.propTypes = {
  isCollapse: PropTypes.bool,
};

export default function NavbarAccount({ isCollapse }) {
  const { user, type } = useAuth();


  return (
    <Link underline="none" color="inherit" component={RouterLink} 
    to={user.type === 'Linguist' ?
     PATH_LINGUIST.user.editProfile :
        user.type === 'Client' ?
        PATH_CLIENT.client.editProfile :
      PATH_DASHBOARD.user.account
      
      }>
      <RootStyle
        sx={{
          ...(isCollapse && {
            bgcolor: 'transparent',
          }),
        }}
      >
        <MyAvatar />

        <Box
          sx={{
            ml: 2,
            transition: (theme) =>
              theme.transitions.create('width', {
                duration: theme.transitions.duration.shorter,
              }),
            ...(isCollapse && {
              ml: 0,
              width: 0,
            }),
          }}
        >
          {(() => {
            switch (type) {
              case 'user':
                return (
                  <>
                    <Typography variant="subtitle2" noWrap>
                      {user?.name}
                    </Typography>
                    <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
                      {user?.jobTitle}
                    </Typography>
                  </>
                );
              case 'Linguist':
                return (
                  <>
                    <Typography variant="subtitle2" noWrap>
                      {user?.name || `${user?.firstName} ${user?.lastName}`}
                    </Typography>
                    <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
                      {user?.type}
                    </Typography>
                  </>
                );
              case 'Technician':
                return (
                  <>
                    <Typography variant="subtitle2" noWrap>
                      {user?.firstName} {user?.lastName}
                    </Typography>
                    <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
                      {user?.type}
                    </Typography>
                  </>
                );
              case 'Formatter':
                return (
                  <>
                    <Typography variant="subtitle2" noWrap>
                      {user?.firstName} {user?.lastName}
                    </Typography>
                    <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
                      {user?.type}
                    </Typography>
                  </>
                );
              case 'Marketeer':
                return (
                  <>
                    <Typography variant="subtitle2" noWrap>
                      {user?.firstName} {user?.lastName}
                    </Typography>
                    <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
                      {user?.type}
                    </Typography>
                  </>
                );
              case 'Client':
                return (
                    <>
                      <Typography variant="subtitle2" noWrap>
                        {user?.name}
                      </Typography>
                      
                    </>
                  );
            }
          })()}

        </Box>
      </RootStyle>
    </Link>
  );
}
