// @mui
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  CardHeader,
  TableContainer,
  Stack,
  Skeleton,
  MenuItem,
  FormControl,
  Select,
  Button,
  TextField,
} from '@mui/material';
import { TableHeadCustom } from 'src/components/table';
import { fCurrency, fNumber } from 'src/utils/formatNumber';
import { useState } from 'react';
import { GET_BARZANO_SUMMERY, GET_CLIENT_INVOICE_STATUS } from 'src/graphQL/queries';
import { useQuery } from '@apollo/client';
import { fDate } from 'src/utils/formatTime';
//CSV Package
import { CSVLink } from 'react-csv';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import Iconify from 'src/components/Iconify';
import { useNavigate } from 'react-router-dom';
import { PATH_DASHBOARD } from 'src/routes/paths';
import _ from 'lodash';
import { getYearsList } from 'src/utils/getYearsList';
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
let TableHeads = [
  { id: '', label: '', align: 'center' },
  { id: '', label: 'Data fattura', align: 'center' },
  { id: '', label: 'Numero fattura', align: 'center' },
  { id: '', label: 'Tipo Pratica', align: 'center' },
  { id: '', label: 'Pratica', align: 'center' },
  { id: '', label: 'Pic', align: 'center' },
  { id: '', label: 'Lingua', align: 'center' },
  { id: '', label: 'Quantità parole', align: 'center' },
  { id: '', label: 'Ripetizioni totali', align: 'center' },
  { id: '', label: 'Prezzo per parola', align: 'center' },
  { id: '', label: 'Importo', align: 'center' },
  { id: '', label: 'Application Type', align: 'center' },
];
var headersETR = [
  { key: 'Data fattura', label: 'Data fattura' },
  { key: 'Numero fattura', label: 'Numero fattura' },
  { key: 'Tipo Pratica', label: 'Tipo Pratica' },
  { key: 'Pratica', label: 'Pratica' },
  { key: 'Pic', label: 'Pic' },
  { key: 'Lingua', label: 'Lingua' },
  { key: 'Quantità parole', label: 'Quantità parole' },
  { key: 'Ripetizioni totali', label: 'Ripetizioni totali' },
  { key: 'Prezzo per parola', label: 'Prezzo per parola' },
  { key: 'Importo', label: 'Importo' },
  { key: 'Application Type', label: 'Application Type' },
];
const monthTranslations = {
  January: 'Gennaio',
  February: 'Febbraio',
  March: 'Marzo',
  April: 'Aprile',
  May: 'Maggio',
  June: 'Giugno',
  July: 'Luglio',
  August: 'Agosto',
  September: 'Settembre',
  October: 'Ottobre',
  November: 'Novembre',
  December: 'Dicembre',
};
const applicationTypeDescription = (applicationType) => {
  const isDivisional = applicationType?.divisional === true;
  const isSequenceList = applicationType?.sequence_list === true;

  if (isDivisional && !isSequenceList) {
    return 'Divisional';
  } else if (!isDivisional && isSequenceList) {
    return 'Sequence List';
  } else if (isDivisional && isSequenceList) {
    return 'Divisional + Sequence List';
  } else {
    return '';
  }
};
export default function BarzanoSummery() {
  const navigate = useNavigate();
  const [year, setYear] = useState(`${new Date().getFullYear()}`);
  const [month, setMonth] = useState('January');
  const [caseType, setCaseType] = useState('E');
  const { data, loading, error } = useQuery(GET_BARZANO_SUMMERY, {
    variables: { year: year, month: month, caseType: caseType },
    fetchPolicy: 'no-cache',
  });
  const {
    data: InvoiceStatusData,
    loading: InvoiceStatusLoading,
    error: InvoiceStatusError,
  } = useQuery(GET_CLIENT_INVOICE_STATUS, {
    variables: { month: `${month}-${year}`, caseType: caseType, orderType: 'Barzano' },
    fetchPolicy: 'no-cache',
  });
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const dataFiltered = applySortFilter({ projects: data?.getBarzanoSummery });
  console.log( {dataFiltered});
  function exportToXLSX(data) {
    let res = data?.map((item, index) => {
      const {
        'Data fattura': dataFattura,
        'Numero fattura': numeroFattura,
        'Tipo Pratica': tipoPratica,
        Pratica,
        Pic,
        Lingua,
        'Quantità parole': quantitaParole,
        'Ripetizioni totali': ripetizioniTotali,
        'Prezzo per parola': prezzoPerParola,
        Importo,
        'Application Type': applicationType,
        ...rest
      } = item;
      return {
        'Data fattura': index === 0 ? dataFattura : '',
        'Numero fattura': numeroFattura,
        'Tipo Pratica': tipoPratica,
        Pratica,
        Pic,
        Lingua,
        'Quantità parole': quantitaParole,
        'Ripetizioni totali': ripetizioniTotali,
        'Prezzo per parola': prezzoPerParola,
        Importo,
        'Application Type': applicationType,
      };
    });
    console.log('Data', res);
    const sheetName = 'Barzanò Summary';
    const worksheet = XLSX.utils.json_to_sheet(res);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    const xlsxBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([xlsxBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, `Riepilogo Pratiche ${caseType} - ${monthTranslations[month]} ${year}.xlsx`);
  }
  const createInvoice = () => {
    navigate(PATH_DASHBOARD.invoice.createClientInvoice, { state: { dataFiltered, month, caseType, year } });
  };
  //   useEffect(()=>{
  // if(InvoiceStatusData){
  //   setInvoice(InvoiceStatusData)
  // }
  //   },[InvoiceStatusData])
  if (error) return `Error: ${error?.message}`;
  return (
    <Stack>
      <Card sx={{ mb: 3 }}>
        <CardHeader
          title="DELIVERED PROJECTS (Monthly)"
          sx={{ mb: 3 }}
          action={
            <Stack direction="row" style={{}}>
                <FormControl variant="standard" sx={{ minWidth: 120, px: 3 }}>
                <Select value={year} name="year" onChange={(e) => setYear(String(e.target.value))} label="Year">
                  {getYearsList()?.map((item) => {
                    return (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl variant="standard" sx={{ minWidth: 120, px: 3 }}>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={month}
                  name="month"
                  onChange={(e) => setMonth(e.target.value)}
                  label="Month"
                >
                  {months?.map((month) => {
                    return (
                      <MenuItem key={month} value={month}>
                        {month}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              <FormControl variant="standard" sx={{ minWidth: 120, px: 3 }}>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={caseType}
                  name="caseType"
                  onChange={(e) => setCaseType(e.target.value)}
                  label="Case Type"
                >
                  {['E', 'ETR']?.map((item) => {
                    return (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            
            </Stack>
          }
        />
        <Card
          sx={{ p: 2 }}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'stretch',
            border: 'none',
            backgroundColor: 'transparent',
            boxShadow: 'none',
          }}
        >
          {loading ? (
            <>
              <Skeleton variant="rounded" sx={{ height: 40, width: 120 }} />
              <Skeleton
                style={{
                  marginLeft: 8,
                }}
                variant="rounded"
                sx={{ height: 40, width: 120 }}
              />
              <Skeleton
                style={{
                  marginLeft: 8,
                }}
                variant="rounded"
                sx={{ height: 40, width: 120 }}
              />
            </>
          ) : (
            <>
              {InvoiceStatusData?.clientInvoiceStatus ? (
               <Button
               style={{
                 marginRight: 8,
               }}
               startIcon={<Iconify icon={'ant-design:euro-circle-outlined'} width={20} height={20} />}
               variant="contained"
               onClick={() => createInvoice(dataFiltered)}
             >
               Create Invoice
             </Button>
              ) : (
                <Button
                  style={{
                    marginRight: 8,
                  }}
                  startIcon={<Iconify icon={'ant-design:euro-circle-outlined'} width={20} height={20} />}
                  variant="contained"
                  onClick={() => createInvoice(dataFiltered)}
                >
                  Create Invoice
                </Button>
              )}

              <Button
                startIcon={<Iconify icon={'eva:arrow-circle-down-fill'} width={20} height={20} />}
                variant="contained"
                onClick={() => exportToXLSX(dataFiltered)}
              >
                Export to XLSX
              </Button>
              <CSVLink
                style={{
                  backgroundColor: '#4CAF50',
                  color: '#fff',
                  padding: '10px',
                  borderRadius: '5px',
                  marginLeft: '7px',
                  textDecoration: 'none',
                  fontWeight: 'bold',
                  fontSize: '0.875rem',
                  '&:hover': {
                    backgroundColor: '#2E8B57',
                    color: '#fff',
                    textDecoration: 'none',
                  },
                }}
                data={dataFiltered}
                headers={headersETR}
                filename={`Riepilogo Pratiche ${caseType} - ${monthTranslations[month]} ${year}.csv`}
              >
                <Card style={{ display: 'flex', alignItems: 'center', backgroundColor: 'transparent', color: '#fff' }}>
                  <Iconify icon={'eva:arrow-circle-down-fill'} width={20} height={20} />
                  <span> Export to CSV</span>
                </Card>
              </CSVLink>
            </>
          )}
        </Card>
        <TableContainer>
          <Table>
            <TableHeadCustom
              headLabel={TableHeads}
              sx={{
                '& .MuiTableCell-head': {
                  bgcolor: '#3be058',
                  color: '#ffff',
                },
                '&.MuiTableHead-root': {
                  padding: 0,
                },
              }}
            />
            <TableBody>
              {loading
                ? [1, 1, 1, 1, 1].map((item, i) => {
                    return (
                      <TableRow key={i}>
                        <TableCell align="center">
                          <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        </TableCell>
                           <TableCell align="center">
                          <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        </TableCell>
                      </TableRow>
                    );
                  })
                : dataFiltered?.map((item, i) => {
                    return (
                      <TableRow key={item?.id}>
                        <TableCell align="center">{i + 1}</TableCell>
                        <TableCell align="center">{item['dataFatture']}</TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">{item['Tipo Pratica']}</TableCell>
                        <TableCell align="center">{item?.Pratica}</TableCell>
                        <TableCell align="center">{''}</TableCell>
                        <TableCell align="center">{item?.Lingua}</TableCell>
                        <TableCell align="center">{fNumber(item['Quantità parole'])}</TableCell>
                        <TableCell align="center">{fNumber(item['Ripetizioni totali'])}</TableCell>
                        <TableCell align="center">{item['Prezzo per parola']}</TableCell>
                        <TableCell align="center">{fCurrency(item?.netTotal)}</TableCell>
                        <TableCell align="center">{applicationTypeDescription(item?.applicationType)}</TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </Stack>
  );
}
function formatDate(date) {
  var day = date.getDate().toString().padStart(2, '0');
  var month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
  var year = date.getFullYear().toString();
  var formattedDate = day + month + year;
  return formattedDate;
}
function applySortFilter({ projects }) {
  let res =
    projects &&
    projects.map((barzano, i) => {
      const pricePerWord = barzano.barzanoItem && barzano.barzanoItem.map((word) => word.pricePerWord);
      let Quantia = barzano?.caseType === 'ETR' ? barzano?.sumTotalWords / barzano?.items : barzano?.sumTotalWords;
      const targetLanguages = [];
      const totalSumRepWords = barzano?.barzanoItem?.reduce((sum, item) => sum + Number(item?.repetitionWords || 0), 0);

      const barzanoItems = barzano?.barzanoItem;
      const targetLangs = barzanoItems[0].targetLanguage;
      for (let j = 0; j < targetLangs?.length; j++) {
        const targetLang = targetLangs[j]?.name;
        targetLanguages.push(targetLang);
      }
      let sourceLanguage = (barzano?.barzanoItem && barzano?.barzanoItem[0].sourceLanguage?.name) || '';
      let Lingua =
        barzano?.caseType === 'ETR'
          ? targetLanguages?.map((item) => String(item).toUpperCase().slice(0, 3)).join(' + ')
          : sourceLanguage.toUpperCase().slice(0, 2);

      return {
        ...barzano,
        'Data fattura': i === 0 ? formatDate(new Date(barzano?.invoiceDate)) : '',
        dataFatture: fDate(barzano?.invoiceDate),
        'Numero fattura': '',
        'Tipo Pratica': barzano?.caseType,
        Pratica:
          barzano?.caseType === 'ETR'
            ? String(barzano?.caseNumber).slice(3).split('-')[0]
            : String(barzano?.caseNumber).slice(1).split('-')[0],
        Pic: '',
        Lingua: Lingua,
        'Quantità parole': Quantia,
        'Ripetizioni totali': totalSumRepWords,
        netTotal: _.round(Number(barzano?.netTotal) + Number.EPSILON, 2).toFixed(2),
        Importo: _.round(Number(barzano?.netTotal) + Number.EPSILON, 2).toFixed(2),
        ppw: pricePerWord,
        'Application Type': applicationTypeDescription(barzano?.applicationType),
        'Prezzo per parola': pricePerWord.join(', '),
      };
    });
  return res;
}
