import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Button, Container, Grid, MenuItem, Skeleton, Stack, TextField } from '@mui/material';
// routes
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useQuery } from '@apollo/client';
import {
  GET_BARZANO_ORDERS_ISSUES_COUNT,
  GET_BARZANO_SALES_REPORT,
  GET_BARZANO_WEEKLY_WORDS_COUNT,
  GET_BARZANO_WORDS_COUNT,
} from 'src/graphQL/queries';
// components
import Page from 'src/components/Page';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import MonthlySales from 'src/sections/@dashboard/reports/barzano/MonthlySales';
import WeeklyWordCount from 'src/sections/@dashboard/reports/barzano/WeeklyWordCount';
import BarzanoSummery from 'src/sections/@dashboard/reports/barzano/BarzanoSummery';
import MonthlyWordCount from 'src/sections/@dashboard/reports/barzano/MonthlyWordCount';
import moment from 'moment';
import LineChart from 'src/sections/@dashboard/reports/barzano/LineChart';
import ProjectsIssuesCount from 'src/sections/@dashboard/reports/barzano/ProjectsIssuesCount';
import ProjectsIssuesChart from 'src/sections/@dashboard/reports/barzano/ProjectsIssuesChart';
import { BsKanban } from 'react-icons/bs';
import { CiViewTable } from 'react-icons/ci';
import TotalSales from 'src/sections/@dashboard/reports/barzano/TotalSales';
import { getYearsList } from 'src/utils/getYearsList';
// import BarzanoMonthlySales from 'src/sections/@dashboard/reports/barzano/BarzanoMonthlySales';

// ----------------------------------------------------------------------

export default function BarzanoReport() {
  const [year, setYear] = useState(`${new Date().getFullYear()}`);
  const [summary, setSummary] = useState(false);
  const [chartOne, setChartOne] = useState([]);
  const [chartTwo, setChartTwo] = useState([]);
  const [creationDate, setCreationDate] = useState([
    moment().startOf('isoWeek').toISOString(),
    moment().endOf('isoWeek').toISOString(),
  ]);
  const { data, loading, error } = useQuery(GET_BARZANO_SALES_REPORT, {
    variables: { year: year },
    fetchPolicy: 'no-cache',
  });
  const {
    data: wordCountsData,
    loading: wordCountsLoading,
    error: wordCountsError,
  } = useQuery(GET_BARZANO_WORDS_COUNT, { variables: { year: year }, fetchPolicy: 'no-cache' });
  const {
    data: weeklyWordsData,
    loading: weeklyWordsLoading,
    error: weeklyWordsError,
  } = useQuery(GET_BARZANO_WEEKLY_WORDS_COUNT, { variables: { creationDate: creationDate }, fetchPolicy: 'no-cache' });
  const { data: ordersIssuesData, loading: ordersIssuesLoading } = useQuery(GET_BARZANO_ORDERS_ISSUES_COUNT, {
    variables: { year: year },
    fetchPolicy: 'no-cache',
  });
  const { data: wordsData, loading: wordsLoading } = useQuery(GET_BARZANO_WORDS_COUNT, {
    variables: { year: year },
    fetchPolicy: 'no-cache',
  });
  const handleFilterYear = (e) => {
    setYear(String(e.target.value));
  };
  const handleFilterCreationDate = async (date) => {
    const newFilterCreationDateTimeFrom = date[0] ? new Date(date[0]).toISOString() : '';
    const newFilterCreationDateTimeTo = date[1] ? new Date(date[1]).toISOString() : '';
    const newFilterCreationDateTimeFromString = newFilterCreationDateTimeFrom ? newFilterCreationDateTimeFrom : '';
    const newFilterCreationDateTimeToString = newFilterCreationDateTimeTo ? newFilterCreationDateTimeTo : '';
    setCreationDate([newFilterCreationDateTimeFrom, newFilterCreationDateTimeTo]);
  };

  useEffect(() => {
    if (wordCountsData) {
      let dataOne = wordCountsData?.getBarzanoWordsCount[`${year}`]?.map((item) => item?.EType);
      let dataOnePast = wordCountsData?.getBarzanoWordsCount[`${Number(year) - 1}`]
        ? wordCountsData?.getBarzanoWordsCount[`${Number(year) - 1}`]?.map((item) => item?.EType)
        : [];
      const zerosToAdd = 12 - dataOne?.length;
      const newDataOne = dataOne.concat(new Array(zerosToAdd).fill(0));
      const Chart1Data = [
        {
          data: [
            { name: `${year}`, data: dataOne },
            { name: `${Number(year) - 1}`, data: dataOnePast },
          ],
        },
      ];
      setChartOne(Chart1Data);
      let dataTwo = wordCountsData?.getBarzanoWordsCount[`${year}`]?.map((item) => item?.ETRType);
      let dataTwopast = wordCountsData?.getBarzanoWordsCount[`${Number(year) - 1}`]
        ? wordCountsData?.getBarzanoWordsCount[`${Number(year) - 1}`]?.map((item) => item?.ETRType)
        : [];
      const zerosToAddTwo = 12 - dataTwo?.length;
      const newDataTwo = dataTwo.concat(new Array(zerosToAddTwo).fill(0));
      const Chart2Data = [
        {
          data: [
            { name: `${year}`, data: dataTwo },
            { name: `${Number(year) - 1}`, data: dataTwopast },
          ],
        },
      ];
      setChartTwo(Chart2Data);
    }
  }, [wordCountsData]);

  return (
    <Page title="Barzanò & Zanardo Report">
      <Container maxWidth={'1000'}>
        <HeaderBreadcrumbs
          heading={'Barzanò & Zanardo Report'}
          links={[{ name: 'Dashboard', href: PATH_DASHBOARD.root }, { name: 'Barzanò & Zanardo Reports' }]}
          action={
            <>
              {summary ? (
                <Button
                  variant="contained"
                  sx={{ marginLeft: '10px' }}
                  onClick={() => {
                    setSummary(false);
                  }}
                >
                  <BsKanban style={{ fontSize: 'x-large' }} />
                </Button>
              ) : (
                <Button
                  variant="contained"
                  sx={{ marginLeft: '10px' }}
                  onClick={() => {
                    setSummary(true);
                  }}
                >
                  <CiViewTable style={{ fontSize: 'x-large' }} />
                </Button>
              )}
            </>
          }
        />
        {summary && (
          <Grid container spacing={5}>
            <Grid item xs={12} sx={{ mb: 2 }}>
              <BarzanoSummery />
            </Grid>
          </Grid>
        )}
        {!summary && (
          <Grid container spacing={5}>
            <Grid item xs={12} sx={{ mb: 2 }}>
              <Stack alignItems="center" justifyContent="center">
                <TextField
                  select
                  label="Year"
                  value={year}
                  onChange={(e) => setYear(String(e.target.value))}
                  SelectProps={{
                    MenuProps: {
                      sx: { '& .MuiPaper-root': { maxHeight: 260 } },
                    },
                  }}
                  sx={{
                    width: { md: 300 },
                    textTransform: 'capitalize',
                  }}
                >
                  {getYearsList().map((option) => (
                    <MenuItem
                      key={option}
                      value={option}
                      sx={{
                        mx: 1,
                        my: 0.5,
                        borderRadius: 0.75,
                        typography: 'body2',
                        textTransform: 'capitalize',
                      }}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6} sx={{ mb: 2 }}>
              <WeeklyWordCount
                salesFilterDate={creationDate}
                hadleFilterDate={handleFilterCreationDate}
                title="DIRECT JOB ORDERS - Wordcount"
                loading={weeklyWordsLoading}
                tableData={weeklyWordsData?.getBarzanoWeeklyWordsCount}
                tableLabels={[
                  { id: '', label: 'E', align: 'center' },
                  // { id: '', label: 'ETR', align: 'center' },
                  { id: '', label: 'Others', align: 'center' },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={6} sx={{ mb: 2 }}>
              <MonthlyWordCount
                salesFilterYear={year}
                hadleFilterYear={handleFilterYear}
                title="DIRECT JOB ORDERS - Wordcount (Monthly)"
                loading={wordCountsLoading}
                tableData={wordCountsData?.getBarzanoWordsCount[`${year}`]}
                tableLabels={[
                  { id: 'month', label: 'Month', align: 'center' },
                  { id: '', label: 'E', align: 'center' },
                  // { id: '', label: 'ETR', align: 'center' },
                  { id: '', label: 'Others', align: 'center' },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={6} sx={{ mb: 2 }}>
              <TotalSales year={year} />
            </Grid>
            <Grid item xs={12} md={6} sx={{ mb: 2 }}>
              <MonthlySales year={year} title="DIRECT JOB ORDERS - Sales (Monthly)" />
            </Grid>

            {/* <Grid item xs={12} md={12} sx={{ mb: 2 }}>
              {
                wordCountsLoading ? <Skeleton height={400} /> :
                  <LineChart
                    title="ETR - Monthly Wordcount Trend"
                    chart={{
                      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                      series: chartTwo,
                      colors: ['#ffc107' ,'#abaaaa']
                    }}
                  />
              }

            </Grid> */}
            <Grid item xs={12} md={12} sx={{ mb: 2 }}>
              {wordCountsLoading ? (
                <Skeleton height={400} />
              ) : (
                <LineChart
                  title="E - Monthly Wordcount Trend"
                  chart={{
                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                    series: chartOne,
                    colors: ['#0000FF', '#abaaaa'],
                  }}
                />
              )}
            </Grid>
            <Grid item xs={12} md={6} sx={{ mb: 2 }}>
              <ProjectsIssuesCount
                salesFilterYear={year}
                hadleFilterYear={handleFilterYear}
                title="External Quality Cases (Monthly)"
                loading={ordersIssuesLoading}
                tableData={ordersIssuesData?.getBarzanoQualityCasesProject}
                tableLabels={[
                  { id: 'month', label: 'Month', align: 'center' },
                  { id: '', label: 'Projects', align: 'center' },
                  { id: '', label: 'Ext Quality Cases', align: 'center' },
                  { id: '', label: 'Ext Quality Cases %', align: 'center' },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={6} sx={{ mb: 2 }}>
              <ProjectsIssuesChart
                title="Chart External Quality Cases (Monthly)"
                loading={ordersIssuesLoading}
                data={ordersIssuesData?.getBarzanoQualityCasesProject}
              />
            </Grid>
            {/* <Grid item xs={12} md={12} sx={{ mb: 2 }}>
              <BarzanoMonthlySales />
            </Grid> */}
          </Grid>
        )}
      </Container>
    </Page>
  );
}
